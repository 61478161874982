body {
  font-family: 'Montserrat', sans-serif;
  margin: 0;
  background-color: black;
  color: white;
}

.homePageComponents {
  display: flex;
  margin-top: 10%;
  justify-content: space-between;
  margin-right: 5%;
}

.homePageText{
  display: flex;
  flex-direction: column;
  font-size: 13em;
  font-weight: bold; 
  letter-spacing: -0.1em;
  line-height: 0.75em;
  margin-top:6%;
  text-align: end;
}

.homePageText span:hover {
  color: rgb(240, 167, 254);
  text-decoration:line-through;
  cursor: pointer;
}

.logo {
   transition: opacity 2s ease-in-out; 
}

.logoNavBar{
  height: 70px;
}

.navBar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 1%;
}

.navBar .textDiv {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.navBar .textDiv span{ 
  text-align: center;
  align-items: center;
  align-self: center;
  font-size: 1em; 
  margin-right: .7em;
  margin-left: .7em;
  letter-spacing: 0.04em;
  font-weight: lighter;
  text-transform: uppercase;
}

.navBar .textDiv span:hover {
  color: black;
  background-color:  rgb(240, 167, 254);
  cursor: pointer;
}

.navBar .imageDiv img:hover { 
  cursor: pointer;
}
 
.projects-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); 
  gap: 20px;  
  padding: 20px;
}

 .project-card {
  border-radius: 10px;
  padding: 15px;
  text-align: center;
  transition: transform 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: left; 
  opacity: .75;
}

.project-card a {
  text-decoration: underline;
  font-size: .9rem;
  color: white;
}

.project-card:hover {
  opacity: 1;
  cursor: pointer;
}

 .project-image {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
}

 .project-card h3 {
  color: #fff;
  margin: 10px 0 5px;
  font-size: 1.2rem;
}

 .project-card p { 
  font-size: .9rem;
  color: white;
}

.aboutText, .logoGithub{
  margin-left: 3%;
}

.logoGithub {
  height: 30px;
}

.logoGithub:hover {
  cursor: pointer;
}

::-moz-selection { 
  color: black;
  background:rgb(240, 167, 254);
}

::selection {
  color: black;
  background:rgb(240, 167, 254);
}

.nerdEmoji {
  height: 150px;
}

@media only screen and (max-width: 431px)  and (max-height: 933px) {

  .homePageText{
    display: flex;
    flex-direction: column;
    font-size: 4.7em;
    font-weight: bold;
    letter-spacing: -0.1em;
    line-height: 0.75em;
    margin-top: 6%;
    position: relative;
    top: 23vh;
    left: -13vw;
    text-align: end;
    text-decoration: underline;
  }

  .logo{
    height: 25vh;
    position: relative;
    left: -10vw;
    top: 20vh;
  }
}